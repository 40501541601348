$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(function () {
    $('.starsong').click(function(e) {
        e.preventDefault();
        var el = $(this);
        var song_id = el.data('song');
        if(song_id != null) {
            $.post('/songs/star', 
                { 
                    id: song_id
                }, function(data) {
                    if(data.status == 'success') {
                        el.toggleClass('done');
                    } else if(data.status == 'full') {
                        swal({   
                            title: "Error!",   
                            text: "Here's my error message!",   
                            type: "error",
                            confirmButtonText: "OK"
                        });
                    }
                }, "json");  
        }
    });

    $('.add-to-compilation').change(function() {
        var el = $(this);
        var song = el.data('id');
        var compilation = el.val();
        $.post('/songs/addtocompilation', 
            { id: song, compilation: compilation }, function(data) {
                if(data.status == 'success') {
                    var comp = $('#song-app-status' + song + '');
                    el.val(0);
                    el.find("option[value='" + compilation + "']").remove();
                    comp.append(data.html);
                }
            }, "json");  
    });

    $('.change-email-submit').click(function(e) {
        e.preventDefault();
        $.post('/profile/email', 
            $('#email-form').serialize(), 
            function(data, status, xhr){
                $('#email-errors').empty();
            })
        .fail(function(jqXHR, textStatus, errorThrown) {
            var errors = jqXHR.responseJSON;
            var title = '<strong>' + Lang.get('layout.something_went_wrong') + '</strong>';
            var errorsHtml= '';
            $.each( errors, function( key, value ) {
                errorsHtml += '<li>' + value[0] + '</li>'; 
            });
            ul = $('<ul class=""></ul>').append(errorsHtml);
            errorDiv = $('<div></div>').attr('class', 'alert alert-danger').append(title).append(ul);
            $('#email-errors').empty().append(errorDiv);
        });
    });

    $('.change-password-submit').click(function(e) {
        e.preventDefault();
        $.post('/profile/password', 
            $('#password-form').serialize(), 
            function(data, status, xhr){
                $('#password-errors').empty();
            })
        .fail(function(jqXHR, textStatus, errorThrown) {
            var errors = jqXHR.responseJSON;
            var title = '<strong>' + Lang.get('layout.something_went_wrong') + '</strong>';
            var errorsHtml= '';
            $.each( errors, function( key, value ) {
                errorsHtml += '<li>' + value[0] + '</li>'; 
            });
            ul = $('<ul class=""></ul>').append(errorsHtml);
            errorDiv = $('<div></div>').attr('class', 'alert alert-danger').append(title).append(ul);
            $('#password-errors').empty().append(errorDiv);
        });
    });

    $('.voting').click(function(e) {
        e.preventDefault();
        var el = $(this);
        var parent = el.parent();
        var compsong = el.data('id');
        var type = el.data('type');
        if(compsong != null) {
            $.post('/compilations/vote', 
                { id: compsong, type:type }, function(data) {
                    if(data.status == 'success') {
                        parent.find('.votescore.plus').text(data.plus);
                        parent.find('.votescore.minus').text(data.minus);
                    }
                }, "json");  
        }
    });

    var avatarUpload = $('#avatar-upload');
    if(avatarUpload.length > 0) {
        avatarUpload.fileupload({
            dataType: 'json',
            singleFileUploads: true,
            dropZone: $('#av-dropzone'),
            formData: {},
            url: BASE_URL + '/profile/avatar',
            add: function (e, data) {
                data.submit();
            },
            done: function (e, data) {
                $.each(data.result.files, function (index, file) {
                     $(".avatar-img").attr("src", file.cover_url);
                });
            },
            progressall: function (e, data) { }
        }).bind('fileuploadstart', function () {

        });
    }
});